import { useEffect } from 'react';
import { getWindowLocation } from 'utils/window';

export const useAvigilonAltaDomainRedirect = () => {
  useEffect(() => {
    const windowLocation = getWindowLocation();

    const url = new URL(windowLocation.href);

    const originalHostname = url.hostname;

    url.hostname = url.hostname
      .replace('access.', 'control.')
      .replace('openpath.com', 'alta.avigilon.com');

    if (originalHostname !== url.hostname) {
      windowLocation.assign(url.toString());
    }
  }, []);
};
