import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper/SvgWrapper';

export const ChevronDownSvg = ({ ...svgProps }) => (
  <SvgWrapper viewBox="0 0 10 10" {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.12038 3.67461C1.30009 3.46495 1.61574 3.44067 1.8254 3.62038L5.00001 6.34147L8.17461 3.62038C8.38428 3.44067 8.69993 3.46495 8.87964 3.67461C9.05935 3.88428 9.03507 4.19993 8.8254 4.37964L5.3254 7.37964C5.13816 7.54013 4.86186 7.54013 4.67461 7.37964L1.17461 4.37964C0.96495 4.19993 0.94067 3.88428 1.12038 3.67461Z"
    />
  </SvgWrapper>
);
