import { OpSelect } from 'new-components/DLS/OpSelect/OpSelect';
import { ComponentProps } from 'react';

// Used in selects or anything with a label: string can be used since this function only needs a label
export type FilterableType =
  | Utils.SingularOfType<ComponentProps<typeof OpSelect>['options']>
  | { label: string; [key: string]: unknown };

export const sortOptionsAlphabetically = (
  optionA: FilterableType,
  optionB: FilterableType,
) => {
  const labelA = String(optionA.label).trim().toLowerCase();
  const labelB = String(optionB.label).trim().toLowerCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};
