// routes off of root path
export const loginRoute = '/login';
export const loginSSORoute = '/loginSSO';
export const forgotPasswordRoute = '/forgotPassword';

export const verifyEmailRoute = '/verifyEmail';
export const resetPasswordRoute = '/resetPassword';
export const setupAppleWalletRoute = '/setupAppleWallet';
export const setupMobileRoute = '/setupMobile';
export const setupMobileSamlRoute = '/setupMobileSaml';
export const cloudKeyUnlockRoute = '/cloudKeyUnlockOld';
export const localAdminRoute = '/localadmin';
export const redirectRoute = '/redirect';
export const intercomDirectoryQrRoute = '/intercomDirectory/qr/:qrCode';
export const intercomDirectoryRoute = '/intercomDirectory/:intercomDirectoryId';
export const mfaManagementRoute = '/mfaManagement';

// app store
export const appMarketplaceRoute = 'appMarketplace';
export const appConfigurationRoute = 'appMarketplace/:appId/config';
export const appDetailsRoute = 'appMarketplace/:appId';
export const appQuantityRequestRoute = 'appMarketplace/:appId/qRequest';
export const licensesRoute = 'licenses';

// routes off of authenticated path (i.e., /o/300/)
export const errorMessageRoute = 'errorMessage';

// Maintenance Route
export const maintenanceRoute = 'maintenance';

// required termsStatus
export const requiredTermsRoute = 'requiredTerms';

// PARTNER MENU
export const partnerDashboardRoute = 'partner/dashboard';
export const partnerBillingRoute = 'partner/billing';
export const partnerStoreRoute = 'partner/store';
export const partnerOrgTableRoute = 'partner/subOrgs';
export const partnerOrgCreateRoute = 'partner/subOrgs/create';
export const partnerOrgEditRoute = 'partner/subOrgs/edit/info';
export const partnerOrgEditPackageRoute = 'partner/subOrgs/edit/package';
export const partnerAcusTableRoute = 'partner/acus';
export const partnerThirdPartyDevicesRoute = 'partner/thirdPartyDevices';
export const partnerEntriesTableRoute = 'partner/entries';
export const partnerAcusEditRoute = 'partner/acus/edit';
export const partnerRequestsTableRoute = 'partner/requests';
export const partnerSubscriptionRenewalsTableRoute = 'partner/renewals';

// DASHBOARDS
export const dashboardContainerRoute = 'dashboards';
export const activityDashboardRoute = 'dashboards/activityDashboard';
export const alarmDashboardRoute = 'dashboards/alarmDashboard';
export const alarmEditRoute = 'dashboards/alarmDashboard/alarm/edit';
export const entryDashboardRoute = 'dashboards/entryDashboard';
export const hardwareDashboardRoute = 'dashboards/hardwareDashboard';
export const customDashboardsRoute = 'dashboards/custom';
export const customDashboardsRoute2 = 'dashboards/custom2';
export const mapsDashboardRoute = 'dashboards/maps';

// USERS
export const usersTableRoute = 'users';

export const usersImportRoute = 'users/import';

export const accessGroupsRoute = 'accessGroups';
export const accessGroupsCreateRoute = 'accessGroups?drawer=add';

export const rolesRoute = 'roles';

export const userSchedulesTableRoute = 'userSchedules';
export const userSchedulesCreateRoute = 'userSchedules/create';
export const userSchedulesEditRoute = 'userSchedules/edit';

export const userCustomFieldsTableRoute = 'userCustomFields';
export const userCustomFieldsCreateRoute = 'userCustomFields/create';
export const userCustomFieldsEditRoute = 'userCustomFields/edit';

// SITES
export const sitesTableRoute = 'sites';
export const sitesCreateRoute = 'sites/create';
export const sitesEditRoute = 'sites/edit';

export const lockdownTableRoute = 'lockdown';
export const lockdownCreateRoute = 'lockdown/create';
export const lockdownEditRoute = 'lockdown/edit';
export const lockdownEditConfigRoute = 'lockdown/edit/config';

export const zonesTableRoute = 'zones';
export const zonesCreateRoute = 'zones/create';
export const zonesEditDetailsRoute = 'zones/edit/details';
export const zonesEditAntiPassbackRoute = 'zones/edit/antiPassback';

export const entriesTableRoute = 'entries';
export const entriesCreateRoute = 'entries/create';
export const entriesEditRoute = 'entries/edit';

export const entryStatesTableRoute = 'entryStates';
export const entryStatesCreateRoute = 'entryStates/create';
export const entryStatesEditRoute = 'entryStates/edit';

export const entrySchedulesTableRoute = 'entrySchedules';
export const entrySchedulesCreateRoute = 'entrySchedules/create';
export const entrySchedulesEditRoute = 'entrySchedules/edit';

export const camerasRoute = 'cameras';
export const cameraDetailsRoute = 'cameras/details';
export const cameraDeeplinkRedirectRoute = 'cameras/deeplinkRedirect';

// HARDWARE
export const acusTableRoute = 'acus';
export const acusCreateRoute = 'acus/create';
export const acusEditRoute = 'acus/edit';
export const acusPortsRoute = 'acus/edit/ports';

export const readersTableRoute = 'readers';
export const readersCreateRoute = 'readers/create';
export const readersEditRoute = 'readers/edit';

export const allegionReadersTableRoute = 'readers/allegion';
export const thirdPartyReadersEditRoute = 'readers/thirdParty/edit';

export const videoReadersRoute = 'videoReaders';
export const videoIntercomReadersRoute = 'videoIntercomReaders';
export const videoIntercomConfigurationRoute = 'voiceAssistant/configure';

export const wirelessLocksTableRoute = 'wirelessLocks';
export const allegionWirelessLocksTableRoute = 'wirelessLocks/allegion';
export const wirelessLocksEditRoute = 'wirelessLocks/edit';
export const wirelessLockGatewaysTableRoute = 'wirelessLockGateways';

export const deviceUpdateManagement = 'deviceUpdateManagement';

// REPORTS
export const scheduledReportsRoute = 'reports/schedule';
export const scheduledReportsEditRoute = 'reports/schedule/edit';
export const scheduledReportsCreateRoute = 'reports/schedule/create';
export const scheduledReportsHistoryRoute = 'reports/schedule/history';

export const activityLogsRoute = 'reports/activityLogs';
export const activityInsightsRoute = 'reports/activityInsights';
export const musterReportsRoute = 'reports/muster';
export const userActivityRoute = 'reports/userActivity';
export const entryActivityRoute = 'reports/entryActivity';
export const userActivitySummaryRoute = 'reports/userActivitySummary';
export const entryActivitySummaryRoute = 'reports/entryActivitySummary';
export const entryAccessAuditRoute = 'reports/entryAccessAudit';
export const userAccessAuditRoute = 'reports/userAccessAudit';
export const portalAuditReportRoute = 'reports/portalAuditReport';
export const visualActivityReportRoute = 'reports/visualActivity';
export const presenceReportRoute = 'reports/presenceReport';
export const userCredentialsManagementRoute = 'reports/credentials';
export const alarmReportRoute = 'reports/alarm';
export const occupancyReportRoute = 'reports/occupancy';

// INTEGRATIONS
export const integrationsRoute = 'integrations';
export const integrationsIdentityProviderGsuite =
  'integrations/identityProviders/gsuite';
export const integrationsIdentityProviderAzure =
  'integrations/identityProviders/msazuread';
export const integrationsIdentityProviderOkta =
  'integrations/identityProviders/okta';
export const integrationsIdentityProviderOnelogin =
  'integrations/identityProviders/onelogin';
export const integrationsIdentityProviderWorkday =
  'integrations/identityProviders/workday';
export const integrationsIdentityProviderConfigRouteTemplate =
  'integrations/identityProviders/_idptCode_?idptCode=_idptCode_&idptId=_idptId_';
export const integrationsSlackRoute = 'integrations/slack';
export const integrationsSdkRoute = 'integrations/sdk';
export const integrationsDensityRoute = 'integrations/density';
export const integrationsInstantCardRoute = 'integrations/instantCard';
export const integrationsApiRoute = 'integrations/api';
export const integrationsWebhooksRoute = 'integrations/webhooks';
export const integrationsZapierRoute = 'integrations/zapier';
export const integrationsSavanceRoute = 'integrations/savance';
export const integrationsEnvoyRoute = 'integrations/envoy';
export const integrationsButterflymxRoute = 'integrations/butterflymx';
export const integrationsCamioRoute = 'integrations/camio';

export const hookActionsTableRoute =
  'integrations/outboundWebhooks/hookActions';
export const hookActionsCreateRoute =
  'integrations/outboundWebhooks/hookActions/create';
export const hookActionsEditRoute =
  'integrations/outboundWebhooks/hookActions/edit';

export const videoProvidersRoute =
  'integrations/videoProviders/:videoProviderId';

export const samlCallbackRoute = 'saml/callback';

// CONFIGURATIONS
export const alarmConfigurationsRoute = 'alarmConfigurations';
export const subscriptionsTableRoute = 'rulesEngine';
export const subscriptionsCreateRoute = 'rulesEngine/create';
export const subscriptionsEditRoute = 'rulesEngine/edit';
export const alertSettingsRoute = 'alertSettings';
export const mobileAppSettingsRoute = 'mobileApp';
export const mobileAppBadgeUiRoute = 'mobileApp/badgeUi';
export const badgeTemplatesTablePageRoute = 'badgeTemplatesTablePage';
export const badgeTemplatesRoute = 'badgeTemplates';
export const intercomUserDirectoryRoute = 'intercomUserDirectory';

export const samlSsoConfigurationRoute = 'samlSso/configure';

// ADMINISTRATION
export const accountPageRoute = 'account';
export const quickStartRoute = 'quickStart';

// REALEASE NOTES
export const releaseNotesRoute = 'releaseNotes';

// super user routes (off of /master)
export const masterDashboardRoute = 'masterDashboard';
export const masterOrgTableRoute = 'orgs';
export const masterOrgEditRoute = 'orgs/edit';
export const masterOrgEditPackageRoute = 'orgs/edit/package';
export const masterOrgEditFeatureFlagRoute = 'orgs/edit/featureFlag';
export const masterOrgCreateRoute = 'orgs/create';

export const masterIdentitiesTableRoute = 'identities';
export const masterIdentitiesEditRoute = 'identities/edit';
export const masterIdentitiesEditSecurityRoute = 'identities/edit/security';
export const masterIdentitiesCreateRoute = 'identities/create';

export const masterRolesTableRoute = 'identitiesRoles';
export const masterRolesEditRoute = 'identitiesRoles/edit';
export const masterRolesCreateRoute = 'identitiesRoles/create';

// NOTE #1: even though AuthenticatedContainer/sagas later prepends a
// 'master/' prefix to the actual URL path, we still need something
// here other than just 'acus', because in config/menuItems.js, we end
// up indexing and searching for objects based on their
// (non-master-prepended) route, and so a plain 'acus' here would
// conflict with acusTablesRoute which also uses 'acus'

// NOTE #2: we can't use anything ending in '/acus' because then
// somehow we end up displaying the wrong title in the breadcrumb
// (somewhere we end up matching the menuConfig for org-level ACUs,
// and then display the breadcrumb for that)
export const masterAcusTableRoute = 'allAcus';
export const masterAcusEditRoute = 'allAcus/edit';

export const masterEntriesTableRoute = 'allEntries';
export const masterThirdPartyDevicesRoute = 'thirdPartyDevices';
export const masterAllegionFobsRoute = 'allegionFobs';
export const masterReleaseNotesRoute = 'masterReleaseNotes';

export const masterFeatureFlagsTableRoute = 'featureFlags';
export const masterFeatureFlagsEditRoute = 'featureFlags/edit';
export const masterFeatureFlagsCreateRoute = 'featureFlags/create';

export const masterAcuSoftwareReportRoute = 'reports/acuSoftware';
export const masterPortalAuditReportRoute = 'reports/masterPortalAuditReport';

export const buildingsRoute = 'buildings';
export const buildingsCreateRoute = 'buildings/create';
export const buildingsEditRoute = 'buildings/edit';

// these four aren't linked to URL bar routes since they're only used in slideouts
// and slideouts do not update the URL/html history
// but I think thats coming in the future
export const floorsCreateRoute = 'buildings/edit/floors/create';
export const floorsEditRoute = 'buildings/edit/floors/edit';

export const unitsCreateRoute = 'buildings/floors/units/create';
export const unitsEditRoute = 'buildings/floors/units/edit';

export const softwareLicensesRoute = 'orgs/edit/subscription';
export const myAppsTableRoute = 'orgs/myApps';
export const requestsTableRoute = 'orgs/requests';
export const subscriptionRenewalSummaryRoute = 'orgs/subscriptions/:subId';

export const userCredentialsEditRoute = 'userCredentials/edit';
